.topBarPlaceholder {
  height: 45px;
}

.topBar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 45px;
  background: #252627;
  padding: 0 max(min(6vw, 140px), 35px);
  z-index: 999;

  .info {
    font-size: 12px;
    font-weight: 600;
    color: #ffffff;

    .infoContact {
      display: inline-block;
    }

    .infoContactMobile {
      display: none;
    }

    .infoCall {
      display: inline-block;
    }
  }

  .social {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    svg {
      color: #ffffff;
      padding-left: 20px;
      cursor: pointer;
    }
  }

  .exitButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    border-radius: 18px;
    height: 33px;
    padding: 0 12px 0 6px;
    margin-left: 20px;
    cursor: pointer;

    span {
      font-weight: 600;
      font-size: 13px;
    }

    svg {
      padding: 0;
      margin-right: 6px;
      font-size: 21px;
      color: #000000;
    }
  }
}

@media (max-width: 1023px) {
  .topBar {
    .info {
      .infoContact {
        display: none !important;
      }

      .infoContactMobile {
        display: inline-block !important;
      }

      .infoCall {
        display: none !important;
      }
    }
  }
}

@media (max-width: 767px) {
  .topBarPlaceholder,
  .topBar {
    display: none !important;
  }
}