.scrollToTop {
  position: fixed;
  right: 30px;
  bottom: 65px;
  transform: translateX(0);
  transition: transform 0.2s;
  z-index: 99;

  &.isHidden {
    transform: translateX(100px);
  }

  .scrollToTopWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    background: #ffffff;
    box-shadow: 0 0 8px rgb(0 0 0 / 10%);
    cursor: pointer;

    svg {
      font-size: 24px;
      color: #D11A6D;
    }
  }
}

@media (max-width: 767px) {
  .scrollToTop {
    right: 20px !important;
    bottom: 130px !important;

    .scrollToTopWrapper {
      width: 50px;
      height: 50px;
      border-radius: 25px;
    }
  }
}