.navPlaceholder {
  height: 90px;
}

.nav {
  position: fixed;
  top: 45px;
  left: 0;
  width: 100%;
  height: 90px;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 max(min(6vw, 140px), 35px);
  transform: translateY(0);
  transition: transform 0.3s;
  z-index: 99;

  &.isHidden {
    transform: translateY(-100px);
  }

  &.isFloating {
    position: fixed !important;
  }

  .navLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 1;
    flex-shrink: 1;
  }

  .navRight {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

  .navLogo {
    margin-right: min(4vw, 45px);
  }

  .navMenu {
    display: flex;
    flex-direction: row;

    .navMenuLink {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      padding: 0 45px;
      transition: 0.2s;
      cursor: pointer;
      user-select: none;

      div {
        transition: 0.2s;
        font-size: 18px;
        font-weight: 600;
        margin-right: 12px;
      }

      svg {
        transition: 0.2s;
      }
    }

    .navMenuLink:hover,
    .navMenuLink.selected {
      div,
      svg {
        color: #9D9D9D;
      }
    }
  }

  .navMenuToggle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;

    div {
      font-size: 18px;
      font-weight: 600;
      margin-left: min(24px, 1vw);
      transition: 0.2s;
    }

    svg {
      font-size: 14px;
      transition: 0.2s;
    }
  }
   
  .navMenuToggle:hover {
    div,
    svg {
      color: #9D9D9D;
    }
  }

  .navButtons {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-left: min(56px, 3vw);
  }
}

.navMegaMenu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: #F1F1F1;
  opacity: 0;
  transform: translateY(-5px);
  transition: all 0.3s;
  z-index: 99;

  .navItemMegaMenuContent {
    display: flex;
    flex-direction: row;
    padding: 20px calc(min(7vw, 185px) - 20px);

    .navItemMegaMenuContentItem {
      width: 33%;
      flex-grow: 1;
    }
  }

  .navMegaMenuContent {
    display: flex;
    flex-direction: row;
    background: #ffffff;

    .navMegaMenuContentLeft {
      display: flex;
      flex-direction: row;
      padding: 90px 70px 90px min(6vw, 140px);
      background: #ffffff;
      border-top: 3px solid #F1F1F1;
      border-bottom: 3px solid #F1F1F1;

      .navMegaMenuContentColumn {
        width: 220px;
        margin-right: 30px;

        .navMegaMenuContentColumnTitle {
          color: #A1A1A1;
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 20px;
        }

        a {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 10px;
          transition: color 0.2s ease-in-out;

          &:hover {
            color: #D11A6D;
          }

          svg {
            margin-left: 10px;
          }
        }

        .weSupport {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          font-size: 18px;
          font-weight: 600;
          margin-top: 30px;

          .title {
            margin-right: 20px;
          }
        }

        .justGiving {
          width: 100%;
          max-width: 250px;
          margin-top: 30px;

          a {
            position: relative;
            display: block;
          }
          
          a > span {
            position: unset !important;
          }

          a img {
            object-fit: contain;
            width: 100% !important;
            position: relative !important;
            height: unset !important;
          }
        }
      }
    }

    .navMegaMenuContentRight {
      padding: 20px min(6vw, 140px) 20px 20px;
      background: #F1F1F1;
      flex-shrink: 1;
      flex-grow: 1;

      .navItemMegaMenuContentItem {
        max-width: 700px;
      }
    }
  }
}

.navMegaMenuShow {
  opacity: 1;
  transform: translateY(0);
}

.navItemMegaMenuContentItem {
  display: flex;
  flex-direction: row;
  margin: 20px;
  padding: 36px;
  background: #ffffff;
  border-radius: 15px;
  flex-grow: 1;

  .navItemMegaMenuContentItemIcon {
    display: flex;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;

    svg {
      color: #ffffff;
      font-size: 24px;
    }
  }

  .navItemMegaMenuContentItemImage {
    display: flex;
    width: 60px;
    height: 60px;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
  }

  .navItemMegaMenuContentItemTitle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: 26px;

    div {
      font-size: 22px;
      font-weight: 500;
      margin-right: 26px;
    }

    svg {
      font-size: 18px;
    }
  }

  .navItemMegaMenuContentItemTitleSmall {
    display: none;
    font-size: 22px;
    font-weight: 500;
    margin-left: 18px;
  }

  .navItemMegaMenuContentItemDescription {
    margin-left: 26px;
    margin-top: 18px;
    margin-right: 50px;

    div {  
      font-size: 13px;
      font-weight: 500;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    svg {
      display: none;
      font-size: 18px;
    }
  }
}

.navMenuFooter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 90px;
  background: #F1BAD3;
  border-bottom: 3px solid #D11A6D;

  div {
    font-size: 18px;
    font-weight: 600;
    color: #D11A6D;
    margin-right: 15px;
  }

  svg {
    color: #D11A6D;
  }
}

.showDesktopSmall {
  display: none !important;
}

.mobileMenu {
  width: 100%;
  height: 100%;
  background: #F1F1F1;
  overflow-y: scroll;
  z-index: 99;

  .mobileMenuContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 28px 36px;

    .weSupport {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      font-size: 18px;
      font-weight: 600;
      margin-top: 30px;

      .title {
        margin-right: 20px;
      }
    }

    .justGiving {
      width: 100%;
      max-width: 250px;
      margin-top: 30px;

      a {
        position: relative;
        display: block;
      }
      
      a > span {
        position: unset !important;
      }

      a img {
        object-fit: contain;
        width: 100% !important;
        position: relative !important;
        height: unset !important;
      }
    }

    .mobileMenuNavSection {
      width: 100%;
      padding: 30px 0 0 0;
      overflow: hidden;

      .mobileMenuNavSectionTitle {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 24px;
        margin-left: 9px;
        cursor: pointer;
        
        div {
          font-size: 18px;
          font-weight: 600;
        }

        svg {
          font-size: 18px;
          color: #919191;
          transition: all 0.3s;
        }
      }

      .mobileMenuNavSectionTitleOpen {
        svg {
          transform: rotate(180deg);
        }
      }

      .mobileMenuNavSectionLinks {
        .mobileMenuNavSectionLink {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding: 14px 0;

          div {
            font-size: 16px;
            font-weight: 600;
            color: #919191
          }

          svg {
            font-size: 18px;
            color: #D11A6D;
          }
        }
      }
    }

    .mobileMenuFooter {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-top: 28px;
      padding: 0 10px;

      .mobileMenuSocials {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        svg {
          font-size: 26px;
          padding: 0 8px;
        }
      }
    }
  }
}

@mixin smallNavItemMegaMenuContentItem {
  flex-direction: column;
  padding: 26px !important;

  .navItemMegaMenuContentItemFirst {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .navItemMegaMenuContentItemIcon {
    width: 45px !important;
    height: 45px !important;
    border-radius: 25px !important;

    svg {
      font-size: 18px;
    }
  }

  .navItemMegaMenuContentItemImage {
    width: 45px !important;
    height: 45px !important;
  }

  .navItemMegaMenuContentItemTitle {
    div {
      font-size: 20px !important;
    }
  }

  .navItemMegaMenuContentItemTitle {
    display: none;
  }

  .navItemMegaMenuContentItemTitleSmall {
    display: block;
    font-size: 20px !important;
  }

  .navItemMegaMenuContentItemDescription {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px 0 0 0;

    div {
      margin-right: 18px;
    }

    svg {
      display: block;
    }
  }
}

@media (max-width: 1800px) {
  .navMegaMenu {
    .navMegaMenuContent {
      .navMegaMenuContentRight {
        .navItemMegaMenuContentItem {
          @include smallNavItemMegaMenuContentItem();
        }
      }
    }
  }
}

@media (max-width: 1600px) {
  .navMegaMenu {
    .navMegaMenuContent {
      .navMegaMenuContentRight {
        .navItemMegaMenuContentItem {
          @include smallNavItemMegaMenuContentItem();
        }
      }
    }
  }
}

@media (max-width: 1535px) {
  .navMenuLink {
    padding: 0 min(1vw, 45px) !important;
  }

  .navMegaMenu {
    .navMegaMenuContent {
      .navMegaMenuContentLeft {
        flex-grow: 1;
        justify-content: center;
        padding: 90px min(6vw, 140px) 90px min(6vw, 140px)
      }

      .navMegaMenuContentRight {
        display: none;
      }
    }
  }

  .navItemMegaMenuContentItem {
    @include smallNavItemMegaMenuContentItem();
  }
}

@media (max-width: 1365px) {
  .navMegaMenuContentItem {
    padding: 25px !important;
  }

  .navMegaMenuContentColumn {
    width: 25% !important;
  }

  .hideDesktopSmall {
    display: none !important;
  }

  .showDesktopSmall {
    display: block !important;
  }
}

@media (max-width: 1023px) {
  .nav {
    justify-content: space-between;
  }

  .navMegaMenu {
    position: fixed;
    top: 135px !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
  }

  .navMenu,
  .navButtons {
    display: none !important;
  }
  
  .navMenuToggle {
    span {
      display: none !important;
    }

    svg {
      font-size: 22px !important;
      color: initial !important;
    }
  }
}

@media (max-width: 767px) {
  .nav {
    top: 0;
  }

  .navMegaMenu {
    top: 90px !important;
  }
}