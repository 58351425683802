html,
body {
  height: 100vh;
  padding: 0;
  margin: 0;
  font-family: europa, sans-serif;
  overflow: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

body.blockScroll {
  position: fixed !important;
  overflow: hidden !important;
  width: 100% !important;
}

#nprogress {
  .bar {
    background: #D11A6D !important;
  }

  .peg {
    box-shadow: 0 0 10px #D11A6D, 0 0 5px #D11A6D !important;
  }

  .spinner-icon {
    border-top-color: #D11A6D;
    border-left-color: #D11A6D;
  }
}

@media (max-width: 767px) {
  .spinner-icon {
    display: none !important;
  }
}

.grecaptcha-badge {
  position: fixed !important;
  bottom: 60px !important;
  left: -187px !important;
  transition: left 0.2s;
  cursor: pointer !important;

  &:hover {
    left: 0 !important
  }
}

#purechat-container {
  iframe {
    transition: right 0.2s !important;
    bottom: 60px !important;
  }

  &.move {
    iframe {
      right: 100px !important
    }
  }
}

@media (max-width: 767px) {
  .grecaptcha-badge {
    bottom: 60px !important;
  }

  #purechat-container {
    iframe {
      transition: right 0.2s !important;
      bottom: 60px !important;
    }
  
    &.move {
      iframe {
        right: 15px !important
      }
    }
  }
}

#main {
  background: #ffffff;
  color: #1F1F1F;
}

.container {
  width: 1700px;
  max-width: 100%;
  padding: 0 max(min(6vw, 140px), 35px);
  margin: 0 auto;
}

.hide-desktop {
  display: none !important;
}

@media (max-width: 1365px) {
  html,
  body {
    overflow-y: auto !important;
    height: auto !important;
  }
}

@media (max-width: 1023px) {
  .hide-tablet {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .hide-mobile {
    display: none !important;
  }
}