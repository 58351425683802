.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 300px;
  height: 60px;
  line-height: 60px;
  padding-left: 35px;
  padding-right: 35px;
  border-radius: 18px;
  cursor: pointer;
  text-decoration: none !important;

  div {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  svg {
    font-size: 16px;
    margin-right: 12px;
  }

  &.loading {
    svg {
      margin: 0 !important;
    }
  }

  &.disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  &.noTitle {
    svg {
      font-size: 22px;
      margin: 0 !important;
    }
  }
}